'use client'

import Image from "next/image"
import { usePathname } from "next/navigation"

import { WithLayoutBorders } from "@/components/layout/with-layout-borders"
import { getAssetUrl, SPECIFICITY, TYPE, IMAGES } from "@/lib/assets"

const HeroBg = () => {
  const pathName = usePathname()

  const useBg = !(pathName.includes('/resources'))
  const heroBg = getAssetUrl(SPECIFICITY.NONE, TYPE.IMAGES, IMAGES.heroBg)

  if (!useBg) {
    return null
  }
  return (
    <Image
      alt="background image"
      src={heroBg}
      fill
      priority
    />
  )
}

export default WithLayoutBorders(HeroBg)