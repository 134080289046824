'use client'

import { useEffect, useState } from "react"
import Image from "next/image"
import { useScrollTop } from "@wojtekmaj/react-hooks";

import { cn } from "@/lib/utils"
import { NavLink } from "@/components/ui/nav-link"
import { getAssetUrl, LOGO, SPECIFICITY, TYPE } from "@/lib/assets"

const LogoHeader = ({

}) => {
  const scrollTop = useScrollTop();

  const [isScrolled, setIsScrolled] = useState(false);

  const sign3Logo = getAssetUrl(SPECIFICITY.LOGO, TYPE.NONE, LOGO.purpeBlack)

  useEffect(() => {
    if (!!scrollTop && scrollTop > 10) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }, [scrollTop])

  return (
    <div
        className={cn(
            "w-screen box-border h-16 px-4 md:px-5 xl:px-0 flex justify-center items-center relative z-50"
        )}
      >
        <div className={cn(
          "w-full shrink-0 xl:w-[1242px] mx-auto box-border h-16 md:px-5 flex justify-between items-center"
        )}>
          <nav className={cn("flex justify-start items-center gap-x-8 h-full box-border")}>
            <NavLink href="/" className={"hover:opacity-1"}>
              <Image
                className={""}
                alt="Sign3 logo"
                src={sign3Logo}
                width={135}
                height={32}
              />
            </NavLink>
          </nav>
        </div>
      </div>
  )
}

export default LogoHeader
